import React, { useState, useEffect } from "react"
import cx from "classnames"
import Collapsible from "react-collapsible"
import { isMobile } from "react-device-detect"
import filterIcon from "@/public/icons/filter.svg"
import closeIcon from "@/public/icons/close.svg"
import Checkbox from "@/components/core/Checkbox/Checkbox"
import { preventBodyScroll } from "@/utils/helper"

const Filter = props => {
  const [collapse, setCollapse] = useState({})
  const [appliedFilters, setAppliedFilters] = useState([])

  const formatFacetName = (name, filter) => {
    let val = name
      .replace(/\./g, "/")
      .replace(/_/g, " ")
      .replace(/^\*{2}/, "")
    if (filter) val += ` - ${filter}`
    return val
  }

  const isSelected = obj => {
    const facet = appliedFilters.find(filter => filter.facet === obj.facet)
    if (!facet) return false
    return facet.display.includes(obj.display) ? true : false
  }

  const handleFilter = (checked, obj, isFilterCancel) => {
    let curFilters = appliedFilters
    const facet = curFilters.find(filter => filter.facet === obj.facet)

    if (checked) {
      if (facet) {
        facet.value.push(obj.value.replace(/"/g, '\\"'))
        facet.display.push(obj.value)
        facet.query.push(obj.query)
      } else
        curFilters.push({
          facet: obj.facet,
          value: [obj.value.replace(/"/g, '\\"')],
          display: [obj.value],
          query: [obj.query],
        })
    } else {
      const index = facet.display.indexOf(obj.value)
      facet.value.splice(index, 1)
      facet.display.splice(index, 1)
      facet.query.splice(index, 1)

      if (!facet.value.length) {
        curFilters = curFilters.filter(f => f.facet !== obj.facet)
      }
    }
    setAppliedFilters([...curFilters]) // spreading is required to break reference

    addAnalyticsData(curFilters, checked, obj, isFilterCancel)
    props.onFilter(curFilters, true)
  }

  // Reason of handling this in a seperate function is to add the appliedFilters in filterArray prop of dataLayer
  const addAnalyticsData = (curFilters, isChecked, obj, isFilterCancel) => {
    const { facet, value: label } = obj

    const filterLabel = label || obj.display

    const appliedOrRemoved = isFilterCancel
      ? "cancel"
      : isChecked
      ? "applied"
      : "removed"
    const eventInfo = {
      eventAction: `find a store:landing:filters:${appliedOrRemoved}`,
      eventName: `find a store:landing:filters:${appliedOrRemoved}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${filterLabel?.toLowerCase()}:${appliedOrRemoved}`,
      internalLinkPosition: "find a store",
      internalLinkType: `find a store:${facet?.toLowerCase()}`,
      filterArray: `${facet?.toLowerCase() + ":" + filterLabel?.toLowerCase()}`,
      internalLinkZoneName: "find a store:landing:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }

    let filterArray = ""
    if (curFilters.length > 0) {
      curFilters.forEach(facetObj => {
        facetObj.value.forEach(facetValue => {
          filterArray +=
            facetObj.facet.toLowerCase() + ":" + facetValue.toLowerCase() + "|"
        })
      })
    }
    eventInfo.filterArray = filterArray?.slice(0, -1)

    if (window.adobeDataLayer) {
      const { adobeDataLayer: { getState } = {} } = window
      const page = (getState && getState("page")) || {}
      window.adobeDataLayer.push({
        event: "cmp:click",
        eventInfo,
        page,
      })
    }
  }

  const handleCollapse = (facet, state) => {
    setCollapse({ ...collapse, [facet]: state })
  }

  const clearAllFilters = () => {
    setAppliedFilters([])
    props.onFilter([], true)
  }

  useEffect(() => {
    if (props.showFilter) {
      window.addEventListener("keydown", handleKeyPress)
    }
    if (isMobile && window.innerWidth < 1024) {
      preventBodyScroll(props.showFilter)
    }
    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      preventBodyScroll(false)
    }
  }, [props.showFilter])
  /**
   *
   * @param {Event} e
   */
  const handleKeyPress = e => {
    const focusElements = '[tabindex]:not([tabindex="-1"])'
    const focusContent = document
      .getElementById("kf-store-filter")
      ?.querySelectorAll(focusElements)
    const lastElem = focusContent[focusContent.length - 1]
    if (e.key === "Tab" && !e.shiftKey && document.activeElement === lastElem) {
      e.preventDefault()
      document.getElementById("kf-store-use-location")?.focus()
    }
  }

  const addFilterExpandCollapseAnalytics = (facet, formatFacetName) => {
    const collapseOrExpand = collapse[facet[0]] ? "collapse" : "expand"

    return JSON.stringify({
      clickInternalLinks: "true",
      eventAction: `find a store:landing:filters:${collapseOrExpand}`,
      eventName: `find a store:landing:filters:${collapseOrExpand}`,
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: `${collapse[facet[0]] ? "collapse" : "expand"}`,
      internalLinkPosition: "find a store",
      internalLinkType: `find a store:${formatFacetName}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: "find a store:landing:filters​",
    })
  }

  const getEventInfo = () => {
    return JSON.stringify({
      eventAction: "find a store:landing:filters:clear all",
      eventName: "find a store:landing:filters:clear all",
      eventType: "navigation",
      eventMsg: "n/a",
      eventStatus: "n/a",
      internalLinkName: "clear all",
      internalLinkPosition: "find a store",
      internalLinkType: "find a store:filters",
      internalLinkZoneName: "find a store:landing:filters",
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    })
  }

  return (
    <div className={cx("filter", "filter--show-less")}>
      <div className="filter__inner-container">
        <div className="product-list__controls filter__mobile-header">
          <button className="product-list__filter-toggle">
            <img
              aria-hidden
              role="presentation"
              src={filterIcon?.src}
              className="product-list__filter-toggle-icon"
              alt="Toggle Filter"
            />
            <span>{props.texts.filters}</span>
          </button>
          <img
            role="button"
            tabIndex="0"
            src={closeIcon}
            className="product-list__close-icon"
            alt="Close Filter"
            onClick={() => props.close(false)}
          />
        </div>

        <div className="filter__chips">
          {appliedFilters.map(facet => {
            return facet.display.map(filter => (
              <div className="filter__chips-tag" key={filter}>
                <span>{filter}</span>
                <img
                  role="button"
                  tabIndex="0"
                  src={closeIcon?.src}
                  alt="Close"
                  className="filter__chips-close-icon"
                  onClick={() =>
                    handleFilter(
                      false,
                      { facet: facet.facet, value: filter, query: filter },
                      true
                    )
                  }
                />
              </div>
            ))
          })}
          {appliedFilters.length > 0 && (
            <div
              className="filter__chips-clear-all gbh-data-layer"
              data-gbh-data-layer={getEventInfo()}
              onClick={clearAllFilters}
              role="button"
              tabIndex="0"
              aria-label={props.texts.clearAll}
            >
              {props.texts.clearAll}
            </div>
          )}
        </div>
        <div id="kf-store-filter">
          {Object.entries(props.facets).map(facet => {
            return (
              <Collapsible
                open={collapse[facet[0]]}
                onTriggerOpening={() => handleCollapse(facet[0], true)}
                onTriggerClosing={() => handleCollapse(facet[0], false)}
                key={facet[0]}
                openedClassName="is-open"
                trigger={
                  <div role="list" tabIndex="0" className="gbh-data-layer">
                    <span aria-hidden="true">{formatFacetName(facet[0])}</span>{" "}
                    <section
                      className="plus gbh-data-layer"
                      data-gbh-data-layer={addFilterExpandCollapseAnalytics(
                        facet,
                        formatFacetName(facet[0])
                      )}
                    >
                      <div className="line-1 line"></div>
                      <div className="line line-2"></div>
                    </section>
                  </div>
                }
                transitionTime={400}
                easing="ease-in-out"
              >
                {facet[1].map(filter => (
                  <Checkbox
                    key={filter.label}
                    id={facet[0] + filter.label}
                    value={filter.label}
                    onChange={e =>
                      handleFilter(e.target.checked, {
                        facet: formatFacetName(facet[0]),
                        value: filter.label,
                        query: filter.query,
                      })
                    }
                    checked={isSelected({
                      facet: formatFacetName(facet[0]),
                      display: filter.label,
                      query: filter.query,
                    })}
                    tabIndex={collapse[facet[0]] ? 0 : -1}
                    checkBoxAria={filter.label}
                  />
                ))}
              </Collapsible>
            )
          })}
        </div>
      </div>
      <div className="filter__footer">
        <button
          className={cx(
            "filter__apply",
            appliedFilters.length > 0 && "filter__apply--active"
          )}
          disabled={appliedFilters.length < 1}
          onClick={() => props.close(false)}
        >
          {props.selectedFilterCount > 0 && appliedFilters.length > 0
            ? `${props.texts.view} ${props.texts.results} (${props.selectedFilterCount})`
            : props.texts.viewResults}
        </button>
      </div>
    </div>
  )
}

export default Filter
