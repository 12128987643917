import useWindowResize from "@/hooks/useWindowResize"
import React, { useEffect, useRef, useState } from "react"
import cx from "classnames"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { isMobile, isTablet } from "react-device-detect"
import { ReactBingmaps } from "react-bingmaps"
import { getConfig } from "@/constants/config"
import { getShortenedUrl, sanitizeInnerHtml } from "@/utils/helper"
import Filter from "@/components/FindStore/v1/Filter"
import AutoSuggestion from "@/components/core/AutoSuggestion/v1/AutoSuggestion"
import styles from "@/components/FindStore/v1/FindStoreLanding.View/index.module.scss"
import StoreList from "@/components/FindStore/v1/StoreList"
import ZipCodeInfoModal from "@/components/core/Modal/ZipCodeInfoModal"
import Button from "@/components/core/Button/Button"
import { FINDLOCATIONKEYCODE } from "@/constants"
import {
  addAnalyticsEvent,
  getFilterEventInfo,
  getStoreEventInfo,
  locationMessageNavigationAnalytics,
} from "@/components/FindStore/v1/analytics"
import filterIcon from "@/public/icons/filter.svg"
const FindStoreLanding = props => {
  const {
    staticTexts = {},
    brandData = {},
    handleChange = () => {},
    facets = [],
    searchTerm = "",
    getGeoLocation = () => {},
    tabList = [],
    // getStoreDetails = () => {},
    findStoreDetails = {},
    findStoresBingKey = "",
    selectedFilterCount = "",
    totalResults = "",
    citySuggestData = {},
    handleFindStoreCheck = () => {},
    findStoreCheckedId = "",
    infoModal = false,
    infoModalError = {},
    authorData = {},
    setInfoModalClose = () => {},
    latitude = 0,
    longitude = 0,
    locationRef = "",
    tabChangeListener = () => {},
    loadMoreHandler = () => {},
    loadingState = false,
    applySelectedFilters = () => {},
  } = props

  const prevShowFilter = useRef(false)

  const { isLandscape } = props
  const [width] = useWindowResize()
  const [showFilter, setShowFilter] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [curStore] = useState(null)

  const [browseAllStoreUrl, setBrowseAllStoreUrl] = useState("")
  const [activeTab, setActiveTab] = useState(0)
  const [mapInfo, setMapInfo] = useState([])
  const [center, setCenter] = useState([latitude, longitude])
  const [locationMessage, setlocationMessage] = useState("")

  const filterClass = cx({
    "find-store-landing__filter": true,
    "pl-0": isMobile ? false : !showFilter,
    "find-store-landing__filter--hide": !showFilter,
    "find-store-landing__filter--modal": isMobile ? showFilter : false,
  })

  useEffect(() => {
    getConfig().then(async config => {
      const browseAllStoreUrl = await getShortenedUrl(
        staticTexts?.ctaLink ||
          _get(config, "general.allStoresPagePath", "") ||
          "/"
      )
      setBrowseAllStoreUrl(browseAllStoreUrl)
    })
  }, [])
  const {
    locationErrormessage = "",
    kohlerStore = "",
    showrooms = "",
    partsDealer = "",
    countryPlaceholder = "",
    ariaLabel = {
      hideFilter: "",
      showFilter: "",
      enterZipcode: "",
      browseAll: "",
      mapGuidelines: "",
      mapMarker: "",
    },
    userMyLocation = "",
    viewList = "",
    viewMap = "",
    browseAllStore = "",
  } = staticTexts
  useEffect(() => {
    getShortenedUrl(locationErrormessage).then(res => {
      setlocationMessage(res)
    })
  }, [])

  const { loadMoreStore = "" } = authorData
  const activePins = {
    0: { text: kohlerStore, icon: tabList[0].icon },
    1: { text: showrooms, icon: tabList[1].icon },
    2: { text: partsDealer, icon: tabList[2].icon },
  }

  // using let variables as we don't need to re-render dom on getting value for this variables.
  let zoomInButton
  let zoomOutButton
  let locateMeButton

  useEffect(() => {
    if (showFilter) {
      document.getElementById("kf-store-filter")?.childNodes &&
        document
          .getElementById("kf-store-filter")
          .childNodes[0]?.querySelector('[tabindex="0"]')
          ?.focus()
    }
    if (prevShowFilter?.current !== showFilter) {
      getFilterEventInfo(showFilter)
      prevShowFilter.current = showFilter
    }
  }, [showFilter])

  useEffect(() => {
    const pins = getPinsBasedOnTab(activeTab)
    const activeData = brandData[tabList[activeTab].key]?.data ?? []
    const changedLatitude = activeData.length
      ? activeData[0].Latitude
      : latitude
    const changedLongitude = activeData.length
      ? activeData[0].Longitude
      : longitude
    if (changedLatitude !== 0) setCenter([changedLatitude, changedLongitude])
    setMapInfo(pins)
    window.wow?.sync()
  }, [activeTab, latitude, longitude])

  const changeTab = tab => {
    setActiveTab(tab)
    tabChangeListener(tab)
  }

  useEffect(() => {
    const aemBreadCrumb = document.getElementById("find-store-breadcrumb")
    if (aemBreadCrumb) {
      aemBreadCrumb.parentNode.style.display = "none"
      document.getElementById("find-store-crumb").appendChild(aemBreadCrumb)
    }
  }, [])

  const handleKeyPress = e => {
    const { keyCode } = e
    const { LEFTARROW, RIGHTARROW } = FINDLOCATIONKEYCODE
    if (keyCode === LEFTARROW || keyCode === RIGHTARROW) {
      let tab
      if (keyCode === LEFTARROW) {
        tab = activeTab - 1
      } else if (keyCode === RIGHTARROW) {
        tab = activeTab + 1
      }
      if (tab < 0) tab = tabList.length - 1
      if (tab >= tabList.length) tab = 0
      document
        .getElementById(`find-store__${tabList[tab].key.toLowerCase()}--tab`)
        .focus()
    }
  }

  useEffect(() => {
    /**
     * These buttons are from a react-bigmaps library.
     * We don't get any events on click of zoom in and out buttons floating over right top side of map
     * so need javascript to add events
     */
    if (
      !window.isZoomButtonLoaded &&
      window.adobeDataLayer &&
      !_isEmpty(findStoresBingKey)
    ) {
      addClickEventOnMap()
    }
    return () => {
      zoomOutButton &&
        zoomOutButton.removeEventListener("click", handleMapZoomOutClick)
      zoomInButton &&
        zoomInButton.removeEventListener("click", handleMapZoomInClick)
      locateMeButton &&
        locateMeButton.removeEventListener("click", handleMapLocateMeClick)
      delete window.isZoomButtonLoaded
    }
  }, [findStoresBingKey])

  const getPinsBasedOnTab = tab => {
    let pins = []
    const currentTab = tab ? tab : activeTab
    const pinsBasedOnActiveTab = brandData[tabList[activeTab].key]?.data ?? []
    if (pinsBasedOnActiveTab.length) {
      pins = pinsBasedOnActiveTab.map(store => {
        const icon = activePins[currentTab].icon || tabList[2].icon
        return {
          location: [store?.Latitude, store?.Longitude],
          option: { icon: icon },
        }
      })
    }
    return pins
  }

  const addClickEventOnMap = () => {
    setTimeout(() => {
      if (!window.isZoomButtonLoaded) {
        zoomInButton = document.getElementById("ZoomInButton")
        if (zoomInButton) {
          window.isZoomButtonLoaded = true
          zoomInButton.addEventListener("click", handleMapZoomInClick)
          zoomOutButton = document.getElementById("ZoomOutButton")
          locateMeButton = document.getElementById("LocateMeButton")
          if (zoomOutButton) {
            zoomOutButton.addEventListener("click", handleMapZoomOutClick)
          }
          if (locateMeButton) {
            locateMeButton.addEventListener("click", handleMapLocateMeClick)
          }
        } else {
          // redundant call to self as we need to wait till map zoom buttons are getting loaded.
          addClickEventOnMap()
        }
      }
    }, 3000) // we need this timeout as it always take time to load buttons on map
  }

  const handleMapZoomInClick = () => {
    addAnalyticsEvent("in")
  }

  const handleMapZoomOutClick = () => {
    addAnalyticsEvent("out")
  }

  const handleMapLocateMeClick = () => {
    addAnalyticsEvent("locate me", true)
  }

  useEffect(() => {
    if (brandData?.needTOReloadTabs) {
      setActiveTab(0)
    }
  }, [brandData?.needTOReloadTabs])

  const isBrandDataEmpty = () => {
    if (!_isEmpty(brandData)) {
      const isBrandDataContainsAnyData = Object.keys(brandData).filter(
        key => brandData[key].data?.length
      )
      return isBrandDataContainsAnyData?.length
    }
    return false
  }

  return (
    <div className={styles.findStoreLandingWrapper}>
      <div className="find-store-landing">
        <ZipCodeInfoModal
          handleModalClose={() => {
            setInfoModalClose()
          }}
          show={infoModal}
          data={infoModalError}
          store={true}
          staticTexts={staticTexts}
          labelledBy="Geo-Location-store-error-message"
          className="message-modal"
        />
        <div className="row ml-0 mr-0">
          <div className="col-12">
            <nav
              id="find-store-crumb"
              className="find-store-landing__breadcrumb"
            ></nav>
            <div className={filterClass}>
              <div className="find-store-landing__filter-panel">
                {showFilter ? (
                  <Filter
                    facets={facets}
                    close={isShow => setShowFilter(isShow)}
                    onFilter={curFilters =>
                      applySelectedFilters(curFilters, activeTab)
                    }
                    displayShare={false}
                    texts={staticTexts}
                    selectedFilterCount={selectedFilterCount}
                    totalResults={totalResults}
                    showFilter={showFilter}
                    locationRef={locationRef}
                  />
                ) : null}
              </div>
            </div>
            <div
              className={`find-store-landing__result-container ${
                isMobile
                  ? width >= 992
                    ? showFilter
                      ? " "
                      : " find-store-landing__result-full"
                    : " col-12"
                  : showFilter
                  ? " "
                  : " find-store-landing__result-full"
              }`}
            >
              <div className="row">
                <div
                  className={`col-lg-5 find-store-landing__search-container ${
                    showMap
                      ? isMobile && (isTablet || isLandscape)
                        ? "col-5"
                        : "col-12"
                      : "col-12"
                  }`}
                >
                  <div className="find-store-landing__search">
                    <AutoSuggestion
                      placeholderLabel={countryPlaceholder}
                      selectedValue={searchTerm}
                      handleChange={selectedVal => handleChange(selectedVal)}
                      handleSelect={selectedVal => handleChange(selectedVal)}
                      optionList={citySuggestData}
                      borderValue={"bottom"}
                      suffix={
                        <img
                          role="button"
                          tabIndex="0"
                          aria-label={
                            showFilter
                              ? ariaLabel.hideFilter
                              : ariaLabel.showFilter
                          }
                          src={filterIcon?.src}
                          alt={ariaLabel.showFilter}
                          onClick={() => setShowFilter(!showFilter)}
                          ariaLabel={ariaLabel.enterZipcode}
                          id={"find-store-landing__search-auto-suggest"}
                        />
                      }
                    />
                  </div>
                  <span
                    role="button"
                    tabIndex="0"
                    id="kf-store-use-location"
                    className="find-store-landing__use-location"
                    onClick={() => getGeoLocation(userMyLocation)}
                    ref={locationRef}
                  >
                    {userMyLocation}
                  </span>
                  {width <= 992 ? (
                    !showMap ? (
                      <span
                        role="button"
                        tabIndex="0"
                        className="find-store-landing__view-list"
                        onClick={() => setShowMap(!showMap)}
                      >
                        {viewMap}
                      </span>
                    ) : (
                      <span
                        role="button"
                        tabIndex="0"
                        className="find-store-landing__view-list"
                        onClick={() => setShowMap(!showMap)}
                      >
                        {viewList}
                      </span>
                    )
                  ) : null}

                  {!showMap ? (
                    <React.Fragment>
                      {isBrandDataEmpty() && !_isEmpty(searchTerm) ? (
                        <div
                          className="find-store-landing__tabs wow animate__fadeInUp"
                          role="tablist"
                        >
                          {tabList.map((tab, i) => {
                            return (
                              <button
                                key={i}
                                className={`find-store-landing__tab gbh-data-layer ${
                                  activeTab === i
                                    ? "find-store-landing__tab--active"
                                    : ""
                                }`}
                                data-gbh-data-layer={getStoreEventInfo(
                                  "inner tabs",
                                  tab.title
                                )}
                                onClick={() => changeTab(i)}
                                onKeyDown={handleKeyPress}
                                role="tab"
                                tabIndex={0}
                                aria-selected={activeTab === i}
                                id={`find-store__${tab.key.toLowerCase()}--tab`}
                              >
                                {tab.title}
                              </button>
                            )
                          })}
                        </div>
                      ) : null}
                      <div
                        role="list"
                        className={`find-store-landing__list ${
                          brandData[tabList[activeTab].key]?.data?.length
                            ? "find-store-landing__show-list"
                            : ""
                        } ${
                          tabList[activeTab].key !== kohlerStore
                            ? "find-store-landing__list-mb"
                            : ""
                        }`}
                      >
                        {!_isEmpty(brandData) &&
                        brandData[tabList[activeTab].key]?.data.length ? (
                          brandData[tabList[activeTab].key]?.data.map(
                            (store, i) => (
                              <StoreList
                                data={store}
                                key={i}
                                index={i}
                                activeTab={activeTab}
                                staticTexts={staticTexts}
                                active={
                                  curStore?.EntityID === store.EntityID
                                    ? true
                                    : false
                                }
                                findStoreDetails={findStoreDetails}
                                handleFindStoreCheck={handleFindStoreCheck}
                                findStoreCheckedId={findStoreCheckedId}
                                isChecked={Boolean(findStoreCheckedId)}
                                tabName={tabList[activeTab].title}
                              />
                            )
                          )
                        ) : !_isEmpty(brandData) && !loadingState ? (
                          <div
                            className={`find-store-landing__message ${
                              brandData[tabList[activeTab].key]?.data.length
                                ? "find-store-landing__store-message"
                                : ""
                            }`}
                          >
                            {sanitizeInnerHtml(
                              !searchTerm
                                ? locationMessage
                                : authorData.nostoresavailable,
                              {
                                replace: domNode => {
                                  const {
                                    type = "",
                                    name = "",
                                    attribs = {},
                                    attribs: { href = "" } = {},
                                    children = [],
                                  } = domNode
                                  if (type === "tag" && name === "a") {
                                    attribs.onClick = () => {
                                      locationMessageNavigationAnalytics({
                                        link: href,
                                        label: children[0]?.data?.toLowerCase(),
                                        zone: "landing:location info",
                                      })
                                    }
                                  }
                                },
                              }
                            )}
                          </div>
                        ) : (
                          <>
                            {/* for temp purpose */}
                            <div>Loading...</div>
                          </>
                        )}
                      </div>
                      <div className="find-store-browseall-div">
                        {!brandData[tabList[activeTab].key]?.isAllLoaded ? (
                          <div>
                            {/* label for temp purpose */}
                            <Button
                              label={
                                !loadingState ? loadMoreStore : "Loading..."
                              }
                              size="large"
                              type="ghost"
                              role="link"
                              aria-label={ariaLabel.browseAll}
                              className="gbh-data-layer"
                              onClick={e => {
                                e.preventDefault()
                                loadMoreHandler(activeTab)
                              }}
                            />
                          </div>
                        ) : null}
                        {tabList[activeTab].key === kohlerStore ? (
                          <Button
                            label={browseAllStore}
                            size="large"
                            type="ghost"
                            role="link"
                            aria-label={ariaLabel.browseAll}
                            className="gbh-data-layer"
                            to={browseAllStoreUrl}
                            data-gbh-data-layer={getStoreEventInfo(
                              "browse all stores",
                              null,
                              browseAllStoreUrl
                            )}
                          />
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
                {
                  <div
                    className={`find-store-landing__map ${
                      showMap
                        ? width <= 992
                          ? isTablet || isLandscape
                            ? "col-7 find-store-landing__show-map"
                            : "col-12 find-store-landing__show-map"
                          : "col-7"
                        : "col-7"
                    } wow animate__fadeInUp`}
                  >
                    {!_isEmpty(findStoresBingKey) ? (
                      <ReactBingmaps
                        bingmapKey={findStoresBingKey}
                        center={center}
                        key={mapInfo}
                        mapTypeId={"grayscale"}
                        disableStreetside={true}
                        navigationBarMode={"square"}
                        zoom={12}
                        mapOptions={{
                          showMapTypeSelector: false,
                          NavigationBarMode: "square",
                        }}
                        pushPins={mapInfo}
                      />
                    ) : null}

                    {findStoresBingKey ? (
                      <div
                        className="find-store-landing__location-note"
                        aria-label={ariaLabel.mapGuidelines}
                      >
                        {tabList
                          ? tabList.map((tab, i) => (
                              <span key={i}>
                                <img
                                  role="presentation"
                                  aria-label={ariaLabel.mapMarker}
                                  src={tab.icon}
                                  alt=""
                                  className="img-fluid"
                                />
                                {isMobile
                                  ? tab.locationNoteMobile
                                  : tab.locationNote}
                              </span>
                            ))
                          : null}
                      </div>
                    ) : null}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindStoreLanding
